.account-info {
    .cards {
        max-width: 956px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        ._card {
            border-radius: 10px;
            margin: 20px;
            flex-grow: 1;
            flex-basis: 25%;
            .content {
                padding: 20px 20px 10px 20px;
                color: #fff;
                h3 {
                    font-family: "DM Sans Bold";
                    font-size: 32px;
                    text-align: center;
                    padding: 20px 0;
                }
                p {
                    font-family: "DM Sans Regular";
                    font-size: 20px;
                    display: flex;
                    flex-direction: row;
                    span {
                        flex-grow: 1;
                        text-align: center;
                    }
                }
                .debit-cards {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    max-width: 250px;
                    margin: 0 auto;
                    padding: 11px 0;
                }
            }
            .footer {
                padding: 10px;
                border-radius: 0 0 10px 10px;
                a {
                    display: flex;
                    flex-direction: row;
                    text-decoration: none;
                    align-items: center;
                    justify-content: center;
                    i {
                        font-family: "DM Sans Bold";
                        font-size: 16px;
                        color: #fff;
                    }
                    img {
                        margin: 0 10px;
                    }
                }
            }
            &.green {
                background: #37ac83;
                box-shadow: 0px 20px 50px rgba(25, 104, 77, 0.3);
                .footer {
                    background: #229b71;
                }
            }
            &.red {
                background: #db6d5b;
                box-shadow: 0px 20px 50px rgba(104, 25, 25, 0.3);
                .footer {
                    background: #ce5642;
                }
            }
            &.yellow {
                background: #e89f3c;
                box-shadow: 0px 20px 50px rgba(104, 96, 25, 0.3);
                .footer {
                    background: #ba7f30;
                }
            }
            &.blue {
                background: #4e73df;
                box-shadow: 0px 20px 50px rgba(104, 96, 25, 0.3);
                .footer {
                    background: #4472bb;
                }
            }
        }
    }
    @media (min-width: 956px) {
        .cards {
            display: flex;
            flex-direction: row;
        }
    }
}
