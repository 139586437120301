.progressbar-wrapper {
    background-color: #fff;
    border-radius: 5px !important;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    height: 200px;

    h2 {
        text-align: center;
        margin-bottom: 20px;
    }

    .progressbar {
        margin: 10px;
        position: relative;
        .progress {
            width: 100px;
            height: 100px;
            text-align: center;
            position: absolute;
            top: 50%;
            margin-top: -15px;
            font-size: 30px;
            text-align: center;
            font-weight: bold;
            width: 100%;
        }
        .spinner {
            border: 8px solid #f3f3f3;
            border-top: 8px solid var(--bg-dark);
            border-radius: 50%;
            width: 100px;
            height: 100px;
            margin: 0 auto;
            animation: spin 700ms linear infinite;
        }
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
    
}
