.transfer {
    .transfer-confirmation {
        background-color: #fff;
        padding: 40px !important;
        width: 350px;
        border-radius: 5px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        
        span {
            position: absolute;
            right: 20px;
            top: 20px;
            font-weight: 600;
            cursor: pointer;
        }

        h2 {
            font-style: normal;
            font-weight: 500;
            color: #020e23;
            font-size: 32px;
            text-align: center;
            margin-bottom: 20px;
        }
        img {
            margin-bottom: 15px;
        }
        p {
            font-size: 18px;
            text-align: center;
            margin-bottom: 15px;
        }
        button {
            color: #fff;
            min-width: 124px;
            margin: 0 5px;
            line-height: 25px;
            border-radius: 10px;
            border: 0;
            padding: 10px;
            font-size: 18px;
            font-weight: 500;
            background-color: #c02;
            cursor: pointer;
        }
    }
}
