.dashboard {
  .rates {
    background-color: rgb(0, 16, 51); 
    overflow:hidden; 
    box-sizing: border-box; 
    text-align: right; 
    line-height:14px; 
    font-size: 12px; 
    font-feature-settings: normal; 
    text-size-adjust: 100%; 
    box-shadow: inset 0 -20px 0 0 #262B38;
    padding:1px;
    padding: 0px; 
    margin: 20px 0; 
    width: 100%;
    > div {
      height:40px; 
      padding:0px; 
      margin:0px; 
      width: 100%;
    }
    iframe {
      border:0;
      margin:0;
      padding:0;
    }
  }
  .ads {
    display: none;
    flex-direction: row;
    justify-content: space-around;
    padding: 30px;
    img {
      border-radius: 10px;
    }
  }
  .cards {
    display: flex;
    flex-direction: column;

    .card {
      padding: 20px;
      border-radius: 5px;
      background-color: #C8D6ED;
      color: #2F4F83;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      border-radius: 5px;
      align-items: center;
      margin: 10px;

      img {
        width: 32px;
        height: 32px;
      }

      h4 {
        text-align: center;
        font-weight: 600;
        width: 170px;
      }

    }
  }
  @media (min-width: 700px) {
    .cards {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .ads {
      display: flex;
    }
  }
}
