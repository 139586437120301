.default-template {
  > .menu {
    background-color: var(--bg-dark);
    display: block;
    transition: flex-basis 300ms ease-in-out;
    flex-basis: 0;
    overflow: hidden;

    h1 {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      color: #fff;
      font-family: "DM Sans Bold";
      font-weight: 700;
      font-size: 17px;
      align-items: center;
      justify-content: center;
      img {
        margin: 20px auto 0 auto;
      }
    }
    .space {
      height: 40px;
    }
    h4 {
      color: #fff;
      padding: 10px 0 10px 30px;
      font-weight: 500;
      font-family: "DM Sans Bold";
    }

    .photo {
      width: 164px;
      margin: 20px auto;
      border-radius: 81px;
      cursor: pointer;
      position: relative;

      img {
        width: 160px;
        height: 160px;
        border: 2px groove #fff;
        border-radius: 80px;
      }
    }
    .contact {
      padding: 10px 20px;
      li {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        align-items: center;
        img {
          margin-right: 8px;
          height: 19px;
        }
        a {
          color: #fff;
        }
      }
    }
    ul {
      list-style: none;
      padding: 0;
      li.item {
        align-items: center;
        color: #fff;
        line-height: 44px;
        box-sizing: border-box;
        cursor: pointer;
        transition: background-color 300ms ease-in-out 1ms;
        padding: 6px;
        padding-left: 40px;
        font-family: "DM Sans Bold";
        font-weight: 700;
        opacity: 0.6;
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          b {
            flex-basis: 130px;
          }
        }
        &.active {
          background: linear-gradient(to right, var(--bg-dark), var(--primary-dark));
          opacity: 1;
        }
        img:first-child {
          height: 24px;
          width: 24px;
          margin-right: 15px;
        }
        ul {
          height: auto;
          max-height: 0;
          transition: max-height 300ms ease-in-out 1ms;
          overflow: hidden;
          padding-left: 40px;
          li {
            a {
              display: block;
              line-height: 20px;
              font-size: 14px;
              padding: 10px;
              color: #fff;
              text-decoration: none;
            }
            opacity: 0.5;
            &.active {
              opacity: 1;
            }
          }
        }
        &.active ul {
          max-height: 600px;
        }
      }
    }
    &.active {
      flex-basis: 250px;
      ul {
        li.item {
          padding-left: 20px;
        }
      }
    }
  }
  @media (min-width: 700px) {
    .menu {
      flex-basis: 250px;
      &.active {
        ul {
          li.item {
            padding-left: 40px;
          }
        }
      }
    }
  }
}
